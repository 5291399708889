@import "../../utils/includes.scss";

//
// Colors
//

$textColor: #000000;
$linkColor: #000000;
$labelColor: #666666;
$formColor: #000000;
$utilColor: #000000;
$priceColor: #000000;
$lightColor: #666666;
$titleColor: #000000;
$titleSecondaryColor: #ffffff;
$subtitleColor: #000000;
$subtitleSecondaryColor: #ffffff;
$successColor: #00d824;
$warningColor:  #ffd900;
$errorColor:  #ff0000;

$bgPrimaryColor: #ffffff;
$bgSecondaryColor: #f2f2f2;


$borderRadiusForm: 0px;
$borderColorForm: #000;
$borderSizeForm: 1px solid;

$garageBackground: #ffffff;
$garageTextColor: #000000;
$garageCarNoteColor: #595959;

$bgTopbarLogin: #E7E7E7;
$colorTopbarLogin: #000000;

$progressBarTitleBG: #000000;
$progressBarTitleColor: #ffffff;
$progressBarTitleCloseBG: #000000;
$progressBarTitleCloseColor: #ffffff;
$progressBarCloseButtonBG: #000000 ;
$progressBarCloseButtonColor: #ffffff;

$calculatorTabsColor: #595959;
$calculatorTabsTextColor: #ffffff;
$calculatorCloseTabs: #595959;
$calculatorCloseTabsColor: #ffffff;
$calculatorMainColor: #ffffff;
$calculatorButton: #000000;
$paymentCalculatorClosedBG: #000000;
$paymentCalculatorClosedColor: #ffffff;
$paymentCalculatorPanelBG: #E7E7E7;
$paymentCalculatorTermWrapperBG: #333333;
$paymentCalculatorTermWrapperColor: #ffffff;
$paymentCalculatorTermSelectedWrapperBG: #ffffff;
$paymentCalculatorTermSelectedColor: #ffffff;
$paymentCalculatorTermBG: #000000;
$paymentCalculatorTermColor: #ffffff;

//
// Fonts
//
$fontFamily: "MINISansSerif-Regular", sans-serif;
$boldFontFamily: "MINISansSerif-Regular", sans-serif;
$boldFontWeight: 700;
$titleFontFamily: "MINISerif-Bold", serif;
$titleFontWeight: $boldFontWeight;
$sansSerif: $fontFamily;
$serif: "MINISerif-Regular", serif;
$isUppercase: uppercase;

//
//   Buttons
//
$btnFontSize:14px;
$btnLineHeight:18px;
$borderRadiusButton: 0px;
$paddingButtonPrimary: 16px 25px;
$paddingButtonSecondary: 8px 16px;

$bgBtnAlpha: transparent;
$colorBtnAlpha: #000000;
$btnColorAlpha: #000000;

$bgBtnBeta: #000000;
$btnColorBeta: #ffffff;
$colorBtnBeta: #ffffff;

$colorBtnOutlineAlpha: #000000;
$colorBtnOutlineBeta: #000000;

$btnColorStep: #ffffff;
$bgBtnStepPrev:  #000000;
$bgBtnStepNext:  #000000;
// ========
// Styles
// ========
.widget-sr{
    @include clearfix;
    width: 100%;

    &.makes__mini{
        font-family: $fontFamily;
        font-size: 16px;
        color: $textColor;
        background-color: $bgPrimaryColor;
        &.Checkout{
            background-color: $bgSecondaryColor
        }
        .is-centered{
            text-align: center;
        }
        .vehicle__name{
            font-family: $boldFontFamily;
            font-weight: $boldFontWeight;
        }
        .vehicle-tile-rebate {
            top: 30px !important;
        }
        //
        // Buttons
        //
        .sr-button-2{
            border: 2px solid #000 !important;
            transition: all .3s ease-in-out;
            &:hover{
                color: #000000;
                border: 2px solid #000 !important;
                background: transparent;
                opacity: 1!important;
            }
        }
        .sr-button-1{
            border: 2px solid #000 !important;
            transition: all .3s ease-in-out;
            &:hover{
                color: #fff;
                background: #000;
                opacity: 1!important;   
            }
            &.btn-register{
                background: #000000;
                color: #ffffff;
                &:hover{
                    color: #D5D5D5;
                }
            }
        }
        .sr-button-1,
        .sr-button-2,
        .sr-button-1:hover,
        .sr-button-2:hover,
        .sr-button-1:focus,
        .sr-button-2:focus{
            box-shadow: none !important;
        }
        .sr-button-1.vehicle-cta-btn,
        .sr-button-2.vehicle-cta-btn{
            height: 47px !important;
            display: flex !important;
            justify-content: center !important;
            align-items: center !important;
            transition: all .3s ease-in-out;
            font-family: 'MINISERIF-BOLD';
            font-weight: unset!important;
            font-size: 16px;
        }
        .showroom-build-and-price .btn-step.sr-button-1{
            border: 2px solid #000000;
            height: 47px;
            transition: all .3s ease-in-out;
            &.is-darker:hover{
                background: #000000;
            }
        }
        .showroom-build-and-price .btn-step.next icon,
        .showroom-build-and-price .btn-step.previous icon{
            display:none;
        }
        .vehicleBanner--image .carouselBtn.sr-button-1.is-small{
            color: #ffffff;
            border-color: #ffffff !important;
        }
        .TransactionActionDetails--wrapper .sr-button-outline-1{
            padding: 16px 25px !important;
            text-transform: uppercase !important;
            font-weight: 700 !important;
            border: 2px solid #000000 !important;
        }
        //
        // Barre de Login
        //
        .widget-login>.widget-login-desktop{
            padding: 0;
        }
        .TransactionSummary--wrapper--title span,
        .TransactionSummary--closeButton .sr-progressBar-closeButton{
            font-size: 12px;
            text-transform: $isUppercase;
        }
        .TransactionSummary--wrapper--title.is-open+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single img {
            opacity: .2;
        }
        .login-content {
            .sr-button-1.is-small,
            .btn-login,
            .btn-register{
                font-size: 12px;
                text-transform: $isUppercase !important;
                transition: all .3s ease-in-out;
            }
            .sr-button-1:focus{
                box-shadow: none;
            }
        }
        .LoginBanner .login-authenticated{
            color: #595959;
        }
        #profile-link.is-light, #logout-link.is-light{
            color: #595959;
        }
        .widget-register-popupElement .SignUpForm .ModalHeader .sub-text{
            color: #000000;
        }
        //
        // Progression transaction
        //
        .TransactionAction--wrapper.sr-text .label{
            justify-content: center;
        }
        .TransactionProgressBar--wrapper{
            background-color: #E0E0E0;
        }
        .TransactionProgressBar--progression{
            background-color: $utilColor;
        }
        .TransactionSummaryUnit--single .validFlag:before{
            background-image: -webkit-linear-gradient(304deg,transparent 50%,$utilColor 55%) !important;
        }
        .TransactionAction--single.is-validate{
            .icon-Crochet:before {
                color: $utilColor;
            }
            button img{
                filter: none;
            }
        }
        .TransactionProgression--uptime .icon-stopwatch{
            color: $utilColor;
        }
        .sr-progressBar .TransactionSummaryUnit--single:nth-child(3){
            background-color: #999999 !important;
            border-bottom-color: #000000 !important;
        }    
        .sr-progressBar .TransactionSummaryUnit--single:nth-child(2){
            background-color: #D5D5D5 !important;
            border-bottom-color: #595959 !important;
            &:before{
                border-left-color: #D5D5D5 !important;
            }
        }
        .sr-progressBar .TransactionSummaryUnit--single:first-child{
            background-color: #ffffff !important;
            border-bottom-color: #D5D5D5 !important;
            &:before{
                border-left-color: #ffffff !important;
            }
        }
        .TransactionSummary--wrapper--title:not(.is-open)+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single.is-validate{
            background-color: #595959 !important;
            border-bottom-color: $utilColor !important;
            &:before{
                border-left-color: #595959 !important;
            }
            .wrapper-icon img{
                -webkit-filter: none !important;
                filter: none !important;
            }
        
        }
        .TransactionSummary--wrapper--title.is-open+.TransactionSummaryUnit--wrapper .TransactionSummaryUnit--single{
            &:first-child{
                border-bottom-color: transparent !important;
            }
            &:nth-child(2){
                border-bottom-color: transparent !important;
            }
            &:nth-child(3){
                border-bottom-color: transparent !important;
            }
        }
        .TimeSavedBlock--wrapper.has-success-background{
            background-color: $utilColor;
        }
        .TransactionAction--single__amount.sr-button-1{
            background: #595959;
            color: #ffffff;
            font-family: $serif;
            &:hover{
                background: #595959;
                color: #ffffff;
                border: none !important;
            }
        }
        .btn-step.previous.sr-button-1,
        .btn-step.next.sr-button-1.is-darker{
            color: #fff !important;
            background: #000 !important;
            &:hover{
                background: #fff !important;
                color: #000 !important;
            }
        }
        //
        // Listings
        //
        .listing-used-button-loading.sr-button-1{
            height: 45px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            transition: all 0.3s ease-in-out;
            &:hover{
                height: 45px;
            }
        }
        .listing-used-button-loading icon:before{
            transition: all .3s ease-in-out;
        }
        // Change for serif font
        .listing-used-filters-head .listing-used-filters-head-title,
        .listing-used-filters-head .listing-used-filters-head-button,
        .listing-used-payment-search .input-search input,
        .listing-used-filters .listing-used-filters-bloc .listing-used-filters-title:not(button),
        .listing-used-filters-category .listing-used-filters-category-list li .category-link,
        .listing-used-filters .listing-used-filters-bloc .listing-used-filters-title,
        .listing-used-filters .slider-labels .slider-label-min,
        .listing-used-filters .slider-labels .slider-label-max,
        .listing-used-filters-clear.sr-link,
        .listing-used-drivetrain,
        .listing-used-bodystyle,
        .listing-tile,
        .financing-section.tile-payment-info .tile-frequency.is-bold,
        .financing-section.tile-payment-info .tile-payment-amount.is-bold,
        .listing-new-tile .listing-new-tile-wrapper,
        .listing-new-tile .listing-new-tile-drivePowerTrains .new-car-name,
        .cash-section.tile-payment-info .payment-row p.is-bold,
        .cash-section.tile-payment-info .payment-row-section,
        .cash-section.tile-payment-info .payment-row-price.is-bold,
        .cash-section.tile-payment-info .payment-row.sr-text,
        .tile-payment-info .payment-rebates .payment-rebate .rebate-amount.is-bold,
        .listing-new-tile .listing-tile-all-price,
        .listing-tile-all-price-title.sr-text,
        .listing-tile-all-price-title.sr-text .is-bold,
        .listing-tile-package-head.is-price.is-bold,
        .listing-tile .listing-tile-all-price-info .price.is-bold,
        .listing-new-tile .listing-tile-all-price .price.is-bold,
        .vehicle-item .vehicle-description,
        .vehicle-item .vehicle-description .vehicle-cost .vehicle-price-label.is-bold,
        .vehicle-item .vehicle-description .vehicle-cost .vehicle-price.is-bold,
        .vehicle-item .vehicle-payment,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-amount.is-bold,
        .section-showroom-header__wrapper,
        .selection-year-bloc__wrapper,
        .selection-year-bloc__model.is-bold,
        .selection-year-bloc__year.is-bold,
        .selection-year-bloc__paymentMethod--title.is-bold,
        .selection-year-bloc__paymentMethod--values .price-highlight.is-bold,
        .style-color__exterior, .style-color__interior,
        .car-equipments_summary .features-bloc,
        .car-equipments_summary .specs-bloc,
        .car-accessories .accessory-bloc,
        .method-button-payment-bar
        {
            font-family: $serif;
        }
        .listing-tile .listing-tile-image .car-name.is-bold{
            font-family: $titleFontFamily;
            font-weight: unset;
            text-shadow: none;
        }
        //remove italic
        .listing-tile .listing-tile-all-price .disclaimer-price,
        .payment-summary-section .listing-tile-all-price .disclaimer-price{
            font-style: normal;
        }
        .listing-used-payment-wrap{
            background: #E7E7E7;
        }
        .listing-used-filters-head{
            background-color: #E7E7E7;
        }
        .vehicle-tile-rebate .icon-promo2.is-price,
        .listing-new-rebate .icon-promo2.is-price,
        .vehicle-rebate .vehicle-rebate-single .icon-promo2.is-price,
        .listing-new-tile .listing-tile-all-price .price.is-price{
            color: #000000;
        }
        .listing-used-filters-category .listing-used-filters-category-list li .category-link.selected.is-price,
        .listing-used-filters-clear.sr-link,
        .listing-tile-package-head.is-price,
        .listing-used-bodystyle.selected:before{
            color: $utilColor;
        }
        .listing-used-filters-bloc.listing-used-metadata-container .metadata-list .metadata-button{
            color: $utilColor;
            font-family: $serif;
            &.selected{
                color: $utilColor;
                border-color: $utilColor;
            }
        }
        .listing-used-payment-search .input-search input::placeholder{
            color: #595959;
        }
        .listing-used-bodystyle.selected.is-price {
            color: $utilColor;
            border-color: $utilColor;
        }
        .sr-slide .rc-slider-track{
            background-color: #000000 !important;
        }
        .sr-slide .rc-slider-handle{
            border-color: #000000 !important;
            background-color: #595959 !important;
        }
        .InputNumber.light-theme input{
            height: 50px;
        }
        .listing-new-tile{
            .listing-tile-car-save,
            .listing-tile-package-description,
            .listing-new-tile-spec .listing-tile-specification-specifics .listing-tile-specification-stock,
            .listing-new-tile-drivePowerTrains,
            .listing-tile-vin,
            .listing-tile-note .sr-link,
            .listing-tile-note .vehicle-note .VehicleDisclaimersContent p,
            .listing-tile-all-price-info .disclaimer-price.is-light{
                color: #000000;
            }
        }
        .listing-tiles-payment{
            .financing-section.tile-payment-info{
                .tile-payment-rate,
                .tile-payment-detail{
                    color: #000000;
                }
            }
        }
        .cash-section.tile-payment-info{
            .payment-row{
                color: #000000;
                p.sr-text{
                    color: #000000;
                }
            }
            .payment-row-section .payment-row-price{
                &.sr-text{
                    color: #000000;
                }
            }
        }
        .listing-tile-all-price-title.sr-text,
        .listing-tile .listing-tile-drivePowerTrains{
            color: #595959;
        }
        .listing-tile .listing-tile-image .car-tagline{
            color: #E7E7E7;
        }
        .listing-tile .listing-tile-image .car-name{
            color: #E7E7E7;
        }
        .listing-tile-note .sr-link{
            font-size: 14px;
            text-decoration: none;
        }
        .tile-payment-info .payment-rebates .payment-rebate{
            color: #000000;
            .rebate-title .icon-promo2.is-primary{
                color: #000000;
            }
        }
        .VehicleDisclaimersContent p{
            color: #000000;
        }
        //
        // Builder
        //
        .section-catalog-filter .catalog-filter-search,
        .section-catalog-filter .catalog-filter__trade-in-section{
            background: #e7e7e7;
            border-color: #595959;
        }
        .section-catalog-filter .catalog-filter-selection .catalog-filter-type .catalog-filter-type-list--single.selected{
            color: #000000;
        }
        .catalog .vehicle-item .is-price,
        .selection-year-bloc__paymentMethod--values .price-highlight.is-price
        {
            color: #000000;
        }
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .taxes-detail,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-cashdown,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-cost-credit,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-rate,
        .vehicle-item .vehicle-payment .vehicle-payment-wrapper .vehicle-payment-rate .vehicle-payment-rate-note,
        .selection-year-bloc__paymentMethod--values .cashdown-highlight, .selection-year-bloc__paymentMethod--values .terms-highlight,
        .selection-year-bloc__disclaimer span:first-child
        {
            color: #595959;
        }
        .vehicle-item .vehicle-description,
        .vehicle-item .vehicle-info{
            background: #E7E7E7;
        }
        .vehicle-item .vehicle-info{
            border: none;
            box-shadow: none;
        }
        .selection-year-bloc{
            border: 1px solid #595959;
            box-shadow: none;
            &:hover{
                .selection-year-bloc__selectButton{
                    background-color: #595959;
                    .sr-button-outline-2{
                        color: #fff;
                        background: #000;
                        border-color: #000;
                    }
                }
            }
            .selection-year-bloc__selectButton{
                .sr-button-outline-2{
                    color: #000000;
                    text-transform: $isUppercase;
                }
            }
            .vehicle-rebate{
                font-family: $serif;
            }
        }
        .style-trim__lists__item{
            box-shadow: none;
            color: #595959;
            border-color: #595959;
            font-family: $serif;
            &.selected.has-price-background{
                background-color: #000000;
                border-color: #000000;
            }
        }
        .bloc-options__single{
            background: #E7E7E7;
            border: none;
            box-shadow: none;
            .bloc-options__single--body .option-list .option{
                color: #000000;
                font-family: $serif;
                icon{
                    color: #000000;
                }
            }
            &--footer{
                background-color: #E7E7E7;
                border-top: 4px solid #E7E7E7;
                font-family: $serif;
                .option-choose-cta{
                    background: transparent;
                    color: #000000;
                    border-radius: 0px;
                    border-color: #000000;
                    box-shadow: none;
                    font-family: $sansSerif;
                    text-transform: uppercase;
                    .is-util{
                        color: #000000;
                    }
                    icon{
                        color: #000000;
                    }
                }
                .option-price.is-price{
                    color: #000000;
                }
            }
            &.selected{
                background: #595959;
                box-shadow: none;
                .bloc-options__single--footer.selected{
                    background-color: #595959;
                    border-radius: 0px;
                    border-top: none;
                    .option-choose-cta{
                        background: $utilColor;
                        color: #fff;
                        border-radius: 0px;
                        border-color: $utilColor;
                        box-shadow: none;
                        .is-util{
                            color: #ffffff;
                        }
                        icon{
                            color: #ffffff;
                        }
                    } 
                }
            }
        }
        .accessory_wrapper.single{
            .accessory_wrapper--content{
                font-family: $serif;
                .accessoryName.sr-text{
                    font-family: $serif;
                }
                .accessory_wrapper--body .is-price{
                    color: #000000;
                }
            }
        }
        .message-handler.warning{
            background-color: #D5D5D5;
            .HeaderMessage--content.is-bold{
                font-family: $serif;
                color: #000000;
                .sr-button-outline-2.is-alternate{
                    color: #000000;
                    border: 2px solid;
                    text-transform: uppercase;
                }
            }
        }
        .section-summary__content-header{
            .vehicle-overview{
                font-family: $serif;
                &__vehicle-title{
                    font-family: $serif;
                    .is-bold,.sr-text.is-bold{
                        font-family: $serif;
                    }
                }
                .financing-section.tile-payment-info{
                    font-family: $serif;
                    .is-bold, .is-price{
                        font-family: $serif;
                    }
                    .is-price{
                        color: #000000;
                    }
                }
            }
        }
        //
        // VDP New
        //
        .listing-new-tile .listing-new-tile-wrapper{
            border: none !important;
            .listing-new-tile .listing-tile-note{
                font-family: $sansSerif;
            }
        }
        .listing-tile-car-save.has-warning-background{
            background-color: $utilColor;
        }
        .section-showroom-header__progressBar-stage{
            color: #595959;
            border-color: #595959;
        }
        .section-showroom-header__progressBar-stage .progressBar-stage__content .icon-showroom{
            color: #595959;
        }
        .section-showroom-header__progressBar-stage.selected {
            color: #fff;
            .progressBar-stage__active-effect {
                background-color: $utilColor !important;
                fill: $utilColor !important;
            }
        }
        .section-showroom-header__progressBar-stage.selected .progressBar-stage__content .icon-showroom{
            color: #595959;
        }
        .financing-section.tile-payment-info .tile-payment-rate .frequency-highlight,
        .vehicle-overview__vehicle-title .vehicle-stockno, .vehicle-overview__vehicle-title .vehicle-vinno{
            color: #595959;
        }
        .section-summary__content .car-equipments_summary>*{
            box-shadow: none;
            border-radius: 0px;
            border: none;
        }
        .vehicle-overview__optionalButton{
            max-width: 320px;
            margin: 0 auto;
        }
        //
        // VDP Used
        //
        .listing-tile, .listing-tile:hover{
            border: none !important;
        }
        .photo-available.is-util.sr-text{
            color: #ffffff;
        }
        .vdp-used-form .sr-title-2.is-alternate,
        .vdp-used-form form .visit-step,
        .vdp-used-form .questions-successfully-sent{
            color: #000000;
        }
        .vdp-used-form .TextArea.styled-placeholder,
        .vdp-used-form .InputText .input-control{
            border-radius: 0px;
            border: 1px solid #595959;
            box-shadow: none;
        }
        .TradeInTile--block, .TradeInTile--content-estimate, .TradeInTile--content-official{
            border-radius: 0px;
            box-shadow: none;
        }
        .vehicleBanner-personsViewed{
            .amount.is-price, .label{
                color: #ffffff;
            }
        }
        //
        // Calculatrice
        //
        .sr-paymentCalculator{
            &-dropdown{
                .is-focused .Select-control,
                .Select-control,
                .Select-option{
                    background-color: transparent !important;
                    border: 1px solid #777 !important;
                    color: #000000 !important;
                }
                .Select-input>input, .Select-value-label{
                    color: #000000 !important;
                }
                
            }
        }
        .calculatorColumn .sr-paymentCalculator-dropdown .Select-input>input,
        .calculatorColumn .sr-paymentCalculator-dropdown .Select-value-label{
            color: #000000 !important;
        }
        .Select-menu-outer{
            background: #333 !important;
            color: #fff !important;
            border-radius: 0px !important;
        }
        .sr-paymentCalculator-Panelbackground .is-light{
            color: #666666;
        }
        .paymentCalculator__paymentsInfos{
            font-family: $serif;
            .is-util{
                color: #ffffff;
            }
            .is-light{
                color: #ffffff;
            }
            .paymentCalculator__payment--details.is-light{
                color: #D5D5D5;
            }
        }
        .calculatorColumn{
            font-family: $serif;
            .sr-link{
                font-family: $sansSerif;
                color: #000000;
            }
        }
        .calculator-term .price.is-util{
            color: #ffffff;
        }
        .sr-paymentCalculator__calculatorTerm--background.selected{
            .term{
                background-color: #595959 !important;
            }
            .price.is-util{
                color: #666666 !important;
            }
            .price span{
                color: #000000 !important;
            }
            .taxesIncluded-section.is-light{
                color: #666666 !important;
                .payment-detail.is-light{
                    color: #666666 !important;
                    span.is-util{
                        color: #000000 !important;
                    }
                }
            }
        }
        .purchase-methods-cash{
            .purchase-detail-line{
                display: flex;
                justify-content: space-between;
                align-items: center;
                &.price_total{
                    background-color: #000000;
                    color: #ffffff;
                    .sr-paymentCalculator-EmphasisColor,
                    .value.is-util{
                        color: #ffffff;
                    }
                }
                .value.is-util{
                    color: #000000;
                }
            }
            .clearfix{
                &:after{
                    content: none !important;
                }
                &:before{
                    content: none !important;
                }
                span.label.rebate.sr-paymentCalculator-EmphasisColor{
                    display:flex;
                    align-items: center;
                }
            }
        }
        .calculator-done{
            border-top: none;
            .sr-button-1.is-small{
                font-size: 13px;
                text-transform: $isUppercase;
                height: 34px;
                padding: 8px 51px;
            }
        }
        .new-customize-payment{
            .customize-payment-details .InputNumber input[type="text"]{
                height: 50px;
            }
            label span.is-util{
                color: #000000;
            }
        }        
        #calculator-toggle-button.sr-button-1{
            background: #595959;
            color: #ffffff;
            border: none;
            transition: all .3s ease-in-out;
            border: none !important;
            &:hover{
                background: #595959;
                color: #000000;
                border: none !important;
            }
        }
        .customize-payment-title,
        .calculator-cash-down .calculator-cash-down-title,
        .calculatorColumn__disclaimer,
        .calculator-tradeIn .calculator-tradeIn-title.is-light,
        .calculatorColumn .InputNumber--add,
        .calculatorColumn .InputNumber--minus,
        .calculatorColumn .InputNumber input,
        .purchase-methods-cash .obligationsTotal .price-title.is-alternate,
        .purchase-methods-cash .pdsf_calculator .price-title.is-alternate,
        .sr-paymentCalculator-Panelbackground .is-light,
        .calculatorColumn.is-light .sr-paymentCalculator-EmphasisColor,
        .calculatorColumn.is-light .sr-paymentCalculator-EmphasisColor .is-alternate,
        .calculatorColumn .section-residual .sr-paymentCalculator-EmphasisColor,
        .calculator-tradeIn .input-format-currency input
        {
            color: #000000;
        }
        .sr-paymentCalculator-dropdown .is-focused .Select-control,
        .sr-paymentCalculator-dropdown .Select-control,
        .sr-paymentCalculator-dropdown .Select-option{
            color: #000000 !important;
        }
        // Remove Border Radius
        .calculatorColumn .InputNumber--add, .calculatorColumn .InputNumber--minus,
        .calculatorColumn .InputNumber input, .customize-payment-details .InputNumber input,
        .calculatorColumn .Select-control, .calculator-tradeIn .input-format-currency,
        .Select-control, .InputNumber--minus,
        .InputNumber--add, .InputText .input-control,
        .listing-new-tile .listing-new-tile-wrapper, .listing-new-tile .listing-new-tile-image .demo-tag,
        .listing-tile, .listing-used-bodystyle,
        .listing-used-filters-bloc.listing-used-metadata-container .metadata-list .metadata-button,
        .vehicle-item .vehicle-info, .selection-year-bloc,
        .style-trim__lists__item, .bloc-options__single,
        .bloc-options__single--footer, .accessory_wrapper.single,
        .Select-menu-outer, .calculator-term,
        .vehicle-overview__summary, .vdp-used-banner-wrapper{
            border-radius: 0px;
        }
        .InputNumber input[type=text]{
            border-width: 1px;
        }
        .purchase-methods-tab__tabs li button{
            font-family: $serif;
        }
        //
        // Modal Pop Up Close button
        //
        .sr-modal--white-close{
            color: #666666;
        }
        .sr-modal-overlay--white .sr-modal--white-close{
            color: #000000;
        }
        .CreditCard__wrapper .Select-menu-outer .Select-menu-option{
            color: #000000;
            background: #ffffff;
        }
        .TransactionSummary--wrapper--title.sr-progressBar-bg-title .is-bold,
        .TransactionAction--wrapper.sr-text .is-bold,
        .TransactionProgression--title.sr-text.is-bold,
        .TransactionActionDetails--wrapper>div h3.is-bold,
        .TransactionActionDetails--wrapper .sr-button-outline-1{
            font-weight: unset !important;
        }
        .widget-login-popupElement .SignInForm .ModalHeader .main-text,
        .widget-register-popupElement .SignUpForm .ModalHeader .main-text{
            text-transform: uppercase;
        }
        .section-catalog-filter .catalog-filter-search,
        .section-catalog-filter .catalog-filter__trade-in-section,
        .listing-used-payment-wrap{
            background: #ffffff !important;
            border-color: #d1d1d1 !important;
            
        }
        .style-color__label,
        .style-color__exterior .style-color__row-top .sr-disclaimer,
        .car-equipments_summary .features-bloc,
        .bloc-options__single--body .option-list .option.consumption .cityFuel,
        .bloc-options__single--body .option-list .option.consumption .HighwayFuel,
        .car-equipments_summary .specs-bloc--content span,
        .car-equipments_summary .features-bloc--content,
        .feature-bloc .is-small.feature-block-title{
            font-size: 14px !important;
        }
        .sr-paymentCalculator-dropdown .is-focused .Select-control,
        .sr-paymentCalculator-dropdown .Select-control,
        .sr-paymentCalculator-dropdown .Select-option{
            color: #000000 !important;
        }
        .catalog{
            background-image: none !important;
        }
        &.-hiddenBuyOnline {
            .showroom-vdp-used,
            .showroom-vdp-new{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        &.-hiddenCatalogBuyOnline {
            .showroom-build-and-price__content{
                .btn-get-started {
                    &.vehicle-cta-btn {
                        display: none;
                    }
                }
            }
        }
        .payment-calculator-wrapper{
            z-index: 9;
        }
        // **********************
        // Header B&B
        // **********************
        @include headerBuildBuy;

        // **********************
        // Barre de progression
        // **********************
        @include progressBar;

        // **********************
        // Formulaires
        // **********************
        @include forms;

        // **********************
        // Par sections
        // **********************
        @include paymentCalculator;
        @include checkout;
        @include sidebarsummary;
        @include login;
        @include garage;
        @include protections;

        @include titles;
        @include buttons;
        @include links;
        @include generics;
        @include baseline;
    }
}
@include breakpoint(mobile){
    .widget-sr{
        &.makes__mini{
            .LoginBanner .LoginBannerWrapper{
                padding: 0;
                height: 34px;
                max-height: 34px;
                .login-content{
                    display: flex;
                    padding: 0;
                    #button-login.btn-login{
                        margin-left: 10px;
                        min-height: 34px;
                    }
                    #button-register.btn-register{
                        margin-left: 10px;
                        min-height: 34px;
                    }
                }
            }
            .vehicle-tile-rebate-single{
                background: $bgBtnAlpha !important;
                .is-price{
                    color: $colorBtnAlpha !important;
                }
                span{
                    color: $colorBtnAlpha !important;
                }
            }
        }
    }
}

@include breakpoint(mobileTablet){
    .widget-sr{
        &.makes__mini{
            .showroom-build-and-price .section__next-prev-step{
                background: #ffffff;
            }
        }
    }
}
@include breakpoint(tablet){
    .widget-sr{
        &.makes__mini{
            .LoginBanner .LoginBannerWrapper{
                .login-content{
                    #button-register.btn-register{
                        width: 142px;
                        max-width: 142px;
                    }
                }
            }
        }
    }
}
@include breakpoint(desktop){
    .widget-sr{
        &.makes__mini{
            .LoginBanner .LoginBannerWrapper{
                .login-content{
                    #button-register.btn-register{
                        width: unset;
                        max-width: unset;
                    }
                }
            }
        }
    }
}