/* website: 3233-minibrossard
 * created at 2021-01-21 4:30 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/mini.scss";
@import "../templates/groups/parkavenue.scss";
@import "../utils/icons.scss";

.widget-sr{
    &.dealer__mini-brossard{
        .TransactionSummary--wrapper--title.sr-progressBar-bg-title .is-bold,
        .TransactionAction--wrapper.sr-text .is-bold,
        .TransactionProgression--title.sr-text.is-bold,
        .TransactionActionDetails--wrapper>div h3.is-bold,
        .TransactionActionDetails--wrapper .sr-button-outline-1{
            font-weight: unset !important;
        }
        .widget-login-popupElement .SignInForm .ModalHeader .main-text,
        .widget-register-popupElement .SignUpForm .ModalHeader .main-text{
            text-transform: uppercase;
        }
        .section-catalog-filter .catalog-filter-search,
        .section-catalog-filter .catalog-filter__trade-in-section,
        .listing-used-payment-wrap{
            background: #ffffff !important;
            border-color: #d1d1d1 !important;
            
        }
        .style-color__label,
        .style-color__exterior .style-color__row-top .sr-disclaimer,
        .car-equipments_summary .features-bloc,
        .bloc-options__single--body .option-list .option.consumption .cityFuel,
        .bloc-options__single--body .option-list .option.consumption .HighwayFuel,
        .car-equipments_summary .specs-bloc--content span,
        .car-equipments_summary .features-bloc--content,
        .feature-bloc .is-small.feature-block-title{
            font-size: 14px !important;
        }
        .sr-paymentCalculator-dropdown .is-focused .Select-control,
        .sr-paymentCalculator-dropdown .Select-control,
        .sr-paymentCalculator-dropdown .Select-option{
            color: #000000 !important;
        }
        .catalog{
            background-image: none !important;
        }
        .instant-estimate__content-title{
            font-family: "MINISerif-Bold", serif;
            font-weight: unset;
            
        }
    }
  }